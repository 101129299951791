import { type IntlDateTimeFormat } from "vue-i18n"

import * as Sentry from "@sentry/vue"

const numberFormats = {
  currency: { style: "currency", currency: "EUR" },
  ...unitFractionDigitsMap,
  undefined: {},
} as const

const dateFormats = {
  monthYear: {
    month: "short",
    year: "numeric",
  },
  dayMonthYear: {
    day: "numeric",
    month: "short",
    year: "numeric",
  },
  dayMonth: {
    day: "numeric",
    month: "short",
  },
} satisfies Record<string, Prettify<IntlDateTimeFormat[string]>>

export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  locale: "fr-FR",
  fallbackLocale: ["en-US"],
  numberFormats: { "en-GB": numberFormats, "en-US": numberFormats, "fr-FR": numberFormats },
  // Can't find a way to display dates in ISO format using i18n.
  // Hence custom by packages/ui/src/components/date.vue
  datetimeFormats: {
    "en-US": dateFormats,
    "en-GB": dateFormats,
    "fr-FR": dateFormats,
  },
  missingWarn: false,
  fallbackWarn: false,
  missing: (locale, key) => {
    const msg = `Missing ${locale} translation for '${key}'`
    console.error(msg)
    Sentry.captureMessage(msg, "warning")
  },
}))
